import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Assembly - Attaching Strengthening Struts"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Step 6. Attaching Strengthening Struts`}</h1>
    <p>{`These are provided not only for the strengthening of the whole structure but they also look good and help bring the whole project to an attractive conclusion. Please see Figs 23 & 24 below for fixing instructions of the strengthening strut from the main beam to the post (Fig23) and from the outside rafter to the post (Fig24). These strengthening struts have been pre-cut at an angle of 45 degrees so when you offer them up to the main beam and rafter for fixing you may find that a small gap appears and that one of the cut faces does not fit flush with either the rafter or main beam. This occurs if the structure of the pergola is not quite square and is slightly out of alignment or the 45 degree angle has changed due to the timber expanding or contracting due to weather conditions. If you find that this has happened try offering up another strengthening strut or invert the strengthening strut you are holding to see if the fit is better. Choose the stregthening strut that fits best and fix using the pre-drilled holes at either end with the 2 inch (50mm) screws provided and you will find that this will help to pull the whole structure together and help reduce any gaps that may have occured.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-6-attaching-strengthening-struts/fig-19.jpg" alt="A diagram displaying how to fit the diagonal strengthening struts to the post and main beam at a 45 degree angle" />
  <span className="text-metadata">Fig 23</span>
    </div>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-6-attaching-strengthening-struts/fig-20.jpg" alt="A diagram displaying how to fit the diagonal side strengthening struts to the post and main beam at a 45 degree angle" />
  <span className="text-metadata">Fig 24</span>
    </div>
    <p>{`If you have specified a either a 9ft x 16ft or a 9ft x 23ft pergola, we have provided another 3 struts (per extra post) for attaching to the rafter, main beam and post for added strength and continuity. See Fig25`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-6-attaching-strengthening-struts/fig-23b.jpg" alt="An isometric diagram displaying how to fit the diagonal strengthening struts to the centre posts of 9ft x 16ft and 9ft x 23ft pergolas" />
  <span className="text-metadata">Fig 25</span>
    </div>
    <p><em parentName="p">{`(`}{`*`}{`NB these drawings are for representation purposes only and not to scale)`}</em></p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      